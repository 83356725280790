import { apiUtils } from './api-utils';


export function getTenants(): Promise<Tenant[]> {
    return apiUtils.get('/tenant');
}

export function createTenant(tenant: Omit<Tenant, 'id'>) {
    return apiUtils.post('/tenant', tenant);
}

export function updateTenant(tenant: Tenant) {
    return apiUtils.put(`/tenant/${tenant.id}`, tenant);
}

export function deleteTenant(tenant: Tenant) {
    return apiUtils.delete(`/tenant/${tenant.id}`);
}

export interface Tenant {
    id: string;
    name: string;
    encompassClientId: string;
    encompassClientSecret: string;
    encompassInstanceId: string;
    instanceProducts: string[];
    initialUserInfo: InstanceUserInfo;
}

export interface InstanceUserInfo {
    id: string;
    encompassUserId?: string;
    encompassUserEmail?: string;
    firstName?: string;
    lastName?: string;
    cognitoUserId?: string;
    frameworkRole?: string;
    encompassInstanceId?: string;
    active: boolean;
    activeInEncompass: boolean;
    instanceId: string;
    activatedDate: string;

    // Computed properties
    fullName: string;
    idAtInstance: string;
}
