import { PublicClientApplication } from '@azure/msal-browser';

import { apiUtils } from '../api/api-utils';


const localApiUrl = 'http://localhost:5179';
const devApiUrl = 'https://dev-api.caprasuite.com';

export const msalInstance = new PublicClientApplication({
    auth: {
        clientId: '75a13b64-c79e-4caa-b223-4a7d1d4b6d1f',
        authority: 'https://login.microsoftonline.com/c83ee60e-be9c-40e7-84ef-b8b9f4ace686',
        redirectUri: window.origin
    }
});

export default async function configureApp() {
    await msalInstance.initialize();

    apiUtils.configure({ apiUrl: devApiUrl });
}
