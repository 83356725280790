import { People } from '@mui/icons-material';

import NavItem from './NavItem';
import styles from './NavMenu.module.scss';


export default function NavMenu() {
    return (
        <nav className={styles.nav}>
            <NavItem
                to="tenant"
                label="Tenants"
                Icon={People}
            />
        </nav>
    );
}
