import { DialogContent, MenuItem, Typography } from '@mui/material';
import {
    Button, DialogActions, RoutedDialog, TextField, replaceItemByKey, usePageMessage,
    useParams
} from '@tsp-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

import {
    InstanceUserInfo, Tenant, createTenant, updateTenant
} from '../api/tenant-api';

import styles from './AddEditTenantDialog.module.scss';
import { TenantContext } from './TenantsPage';


interface TenantPageParams {
    tenantId: string;
}

export default function AddEditTenantDialog({ ...props }) {
    const { id: tenantId } = useParams<TenantPageParams>();
    const { tenants, setTenants } = useContext(TenantContext);
    const pageMessage = usePageMessage();
    const navigate = useNavigate();

    const [ enabledProducts, setEnabledProducts ] = useState<string[]>([]);
    const [ loading, setLoading ] = useState(false);

    const isCreate = !tenantId;
    const tenant = tenants.find(t => t.id === tenantId);

    const formMethods = useForm<Tenant>({ defaultValues: tenant });
    const {
        handleSubmit
    } = formMethods;

    const products = tenant?.instanceProducts;
    useEffect(() => {
        setEnabledProducts(products || []);
    }, [ products ]);

    async function onSubmit(data: Tenant) {
        setLoading(true);
        try {
            if (isCreate) {
                const newTenant = await createTenant(data);
                setTenants([ ...tenants, newTenant ]);
                pageMessage.success('Tenant added successfully');
            } else {
                const updatedTenant = await updateTenant(data);
                setTenants(prevTenant => replaceItemByKey(prevTenant, updatedTenant, 'id'));
                pageMessage.success('Tenant edited successfully');
            }
            navigate('..');
        } catch (error) {
            pageMessage.handleApiError(`An error occurred while ${isCreate ? 'adding' : 'editing'} tenant details`, error);
        }
        setLoading(false);
    }

    const title = isCreate ? 'Add Tenant' : 'Edit Tenant';

    return (
        <RoutedDialog
            {...props}
            maxWidth={false}
            title={title}
            closeTo=".."
        >
            <DialogContent className={styles.content}>
                <form
                    id={tenantFormID}
                    className={styles.form}
                    onSubmit={handleSubmit(onSubmit)}
                >
                    <FormProvider {...formMethods}>

                        <TextField<Tenant>
                            name="name"
                            label="Name"
                            required
                        />

                        <TextField<Tenant>
                            name="instanceProducts"
                            label="Enabled products"
                            select
                            SelectProps={{ multiple: true }}
                        >
                            {enabledProducts.map((product) => (
                                <MenuItem
                                    key={product}
                                    value={product}
                                >
                                    {product}
                                </MenuItem>
                            ))}

                        </TextField>

                        <Typography
                            fontWeight={500}
                            color="textSecondary"
                        >
                            Encompass info
                        </Typography>

                        <br />

                        <TextField<Tenant>
                            name="encompassClientId"
                            label="Client Id"
                            required
                        />

                        <TextField<Tenant>
                            name="encompassClientSecret"
                            label="Client secret"
                            required
                        />

                        <TextField<Tenant>
                            name="encompassInstanceId"
                            label="Instance Id"
                            required
                        />

                        <br />

                        {isCreate && (
                            <>
                                <Typography
                                    fontWeight={500}
                                    color="textSecondary"
                                >
                                    Initial User info
                                </Typography>

                                <br />

                                <TextField<InstanceUserInfo>
                                    name="encompassUserId"
                                    label="User Id"
                                    required
                                />

                                <TextField<InstanceUserInfo>
                                    name="encompassUserEmail"
                                    label="User email"
                                    required
                                />

                                <TextField<InstanceUserInfo>
                                    name="firstName"
                                    label="First name"
                                    required
                                />

                                <TextField<InstanceUserInfo>
                                    name="lastName"
                                    label="Last name"
                                    required
                                />
                            </>
                        )}
                    </FormProvider>
                </form>
            </DialogContent>

            <DialogActions>
                <Button
                    type="submit"
                    variant="contained"
                    color="secondary"
                    disabled={loading}
                    form={tenantFormID}
                >
                    Save
                </Button>
            </DialogActions>
        </RoutedDialog>
    );
}

const tenantFormID = 'tenantForm';
