import {
    CssBaseline, StyledEngineProvider, ThemeProvider, Toolbar, Typography
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import {
    ConfirmDialog, ConfirmDialogContext, Loader, PageMessageContext,
    useAsyncEffect, useConfirmDialogValue, usePageMessageValue
} from '@tsp-ui/core';
import React, { useCallback, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

import styles from './App.module.scss';
import { initializeAuth } from './api/api-utils';
import configureApp from './config/configureApp';
import { theme } from './config/mui-theme';
import NavMenu from './views/NavMenu';
import TenantsPage from './views/TenantsPage';


function App() {
    const pageMessageValue = usePageMessageValue();
    const confirmDialogValue = useConfirmDialogValue();

    const [ loading, setLoading ] = useState(true);
    const [ hasStartupError, setHasStartupError ] = useState(false);
    const [ isAuthenticated, setIsAuthenticated ] = useState(false);

    useAsyncEffect(useCallback(async () => {
        if (!isAuthenticated && !hasStartupError) {
            try {
                await configureApp();
                await initializeAuth();
                setIsAuthenticated(true);
            } catch (error) {
                console.error('Authentication failed:', error);
                setHasStartupError(true);
            } finally {
                setLoading(false);
            }
        }
    }, [ hasStartupError, isAuthenticated ]));

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <PageMessageContext.Provider value={pageMessageValue}>
                        <ConfirmDialogContext.Provider value={confirmDialogValue}>

                            <CssBaseline />

                            <ConfirmDialog />

                            <div className={styles.root}>
                                <header className={styles.headerContainer}>
                                    <Toolbar className={styles.toolbar}>
                                        <div className={styles.logoContainer}>
                                            <img
                                                src="/logo.svg"
                                                alt="CapraSuite Logo"
                                                className={styles.logo}
                                            />

                                            <Typography variant="h6">
                                                CapraSuite
                                            </Typography>
                                        </div>

                                        <NavMenu />
                                    </Toolbar>
                                </header>

                                {hasStartupError ? (
                                    <Typography>
                                        We ran into a problem starting the app, please contact support.
                                    </Typography>
                                ) : isAuthenticated ? (
                                    <Routes>
                                        <Route
                                            path="tenant/*"
                                            element={(
                                                <TenantsPage />
                                            )}
                                        />
                                    </Routes>
                                ) : (
                                    <Loader loading={loading} />
                                )}
                            </div>
                        </ConfirmDialogContext.Provider>
                    </PageMessageContext.Provider>
                </LocalizationProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default App;
