import { AccountInfo } from '@azure/msal-browser';
import { createApiUtils } from '@tsp-ui/core/utils';

import { msalInstance } from '../config/configureApp';


let authToken = '';

export const apiUtils = {
    ...createApiUtils({
        getAuthToken: async () => {
            if (!authToken) {
                await authenticate();
            }
            return authToken;
        },
        getRequestUrl: (url, options, config) => config?.apiUrl + url
    })
};

async function authenticate() {
    const accounts: AccountInfo[] = msalInstance.getAllAccounts();

    const msalConfig = msalInstance.getConfiguration();

    let token: string;
    if (accounts.length > 0) {
        const silentRequest = {
            scopes: [ `api://${msalConfig.auth.clientId}/user_impersonation` ],
            account: accounts[0]
        };
        const response = await msalInstance.acquireTokenSilent(silentRequest);
        token = response.accessToken;
    } else {
        const response = await msalInstance.loginPopup();
        token = response.accessToken;
    }

    authToken = `Bearer ${token}`;
}

export async function initializeAuth() {
    return await authenticate();
}
