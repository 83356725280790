import { Edit } from '@mui/icons-material';
import {
    Button,
    CardTable, IconButton, RoutedDialogManager, useAsyncEffect, usePageMessage
} from '@tsp-ui/core';
import React, {
    Dispatch, SetStateAction, createContext, useCallback, useState
} from 'react';
import { Link } from 'react-router-dom';

import { Tenant, getTenants } from '../api/tenant-api';

import AddEditTenantDialog from './AddEditTenantDialog';
import Page from './Page';
import styles from './TenantsPage.module.scss';


interface TenantContextType {
    tenants: Tenant[];
    setTenants: Dispatch<SetStateAction<Tenant[]>>;
}

export const TenantContext = createContext<TenantContextType>({
    tenants: [],
    setTenants: () => {}
});

export default function TenantsPage() {
    const pageMessage = usePageMessage();

    const [ loading, setLoading ] = useState(false);
    const [ tenants, setTenants ] = useState<Tenant[]>([]);

    useAsyncEffect(useCallback(async () => {
        setLoading(true);
        try {
            setTenants(await getTenants());
        } catch (error) {
            pageMessage.handleApiError('An error occurred while fetching Tenants', error);
        } finally {
            setLoading(false);
        }
    }, [ pageMessage ]));

    const tenantContextValue = {
        tenants,
        setTenants
    };

    return (
        <TenantContext.Provider value={tenantContextValue}>
            <Page
                loading={loading}
                className={styles.container}
                headerActions={(
                    <Button
                        component={Link}
                        to="add"
                        color="secondary"
                        variant="contained"
                    >
                        Add Tenant
                    </Button>
                )}
                header="Tenants"
            >
                <CardTable
                    headers={tableHeaders}
                    className={styles.table}
                >
                    {tenants.map((tenant) => (
                        <tr key={tenant.id}>
                            <td>{tenant.name || '--'}</td>

                            <td>{tenant.instanceProducts ? tenant.instanceProducts.length : '--'}</td>

                            <td>{tenant.encompassClientId || '--'}</td>

                            <td>{tenant.encompassClientSecret || '--'}</td>

                            <td>{tenant.encompassInstanceId || '--'}</td>

                            <td className={styles.iconButtonsContainer}>
                                <IconButton
                                    component={Link}
                                    to={`${tenant.id}/edit`}
                                    tooltip="Edit"
                                    color="secondary"
                                >
                                    <Edit />
                                </IconButton>
                            </td>
                        </tr>
                    ))}
                </CardTable>

                <RoutedDialogManager routes={dialogRoutes} />
            </Page>
        </TenantContext.Provider>
    );
}

const tableHeaders = [
    'Name',
    'Enabled products',
    'Client Id',
    'Client secret',
    'Instance Id',
    ''
];

const dialogRoutes = {
    add: AddEditTenantDialog,
    ':id/edit': AddEditTenantDialog
};
